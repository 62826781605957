<template>
  <b-card-code title="جدول التصنيفات الثانوية" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="ابحث عن.."
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                الغاء الفلتر
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة تصنيف ثانوي جديد</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col md="12" >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              v-b-modal.model-e-w
              @click="eRM(action.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-modal.modal-center
              @click="dRM(action.item)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
            </b-button>
          </b-col>
        </b-dropdown>
      </template>
      <template #cell(link)="link">
        <b-button
          v-if="link"
          v-ripple.400="'rgba(0, 207, 232, 0.15)'"
          variant="outline-info"
          @click="onClicked(link.item)"
        >
          عرض
        </b-button>
        <span v-else class="align-middle">NO file</span>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ التعديلات"
      centered
      size="lg"
      title="تعديل تصنيف ثانوي"
      @ok="updateResearch"
      @hidden="rEF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
        <b-col md="12">
            <b-form-group>
              <label for="basic-password">تصنيف رئيسي</label>
              <v-select
                v-model="editform.category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="categories"
                placeholder="اختر تصنيف رئيسي"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">عنوان بالعربي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.ar_title"
              :state="editform.ar_title.length > 0"
              placeholder="العنوان بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.en_title"
              :state="editform.en_title.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>
          
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="اضافة تصنيف ثانوي جديد"
      @ok="aNR"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
        <b-col md="12">
            <b-form-group>
              <label for="basic-password">تصنيف رئيسي</label>
              <v-select
                v-model="form.category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="categories"
                placeholder="اختر تصنيف رئيسي"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">عنوان بالعربي:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.ar_title"
              :state="form.ar_title.length > 0"
              placeholder="العنوان بالعربي"
            />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-invalid1"
              v-model="form.en_title"
              :state="form.en_title.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>
          
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-center"
      centered
      title="Delete research"
      @ok="deleteResearch"
      ok-title="Accept"
    >
      <b-card-text>
        هل تريد حذف هذه الحقل " {{ this.editform.en_title }} "
        ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,BProgress,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    BAvatar,
    BBadge,
    BFormRow,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,BProgress,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    vSelect,
    BFormInvalidFeedback,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      role: null,
      canSelect: false,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 0,
      filter: "",
      categories: [],
      fields: [
        {
          key: "ar_title",
          label: "العنوان بالعربي",
        },
        {
          key: "en_title",
          label: "العنوان بالانكليزي",
        },
        {
          key: "category.ar_title",
          label: "تصنيف الرئيسي ",
        },
        { key: "action", label: "الاجراءات" },
      ],
      items: [],
      notifi: {
        route: "",
        title: "",
        item_id: "",
        table: "",
        users: [],
        departments: [],
        role_id: "",
        type: "",
        subtitle: "",
        bg: "",
      },
      form: {
        en_title: "",
        ar_title: "",
        category_id: "",
      },
      editform: {
        en_title: "",
        ar_title: "",
        category_id: "",
        id: null,
      },
    };
  },
  watch: {
    filter: function (val) {
      if (val == null) {
        this.filter = null;
      }
      this.gS();
    },
    
    "$route.params.id": {
      handler: function (search) {
        this.gS2();
      },
    },
  },
  computed: {},
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gS(), this.gSM1();
  },
  watch:{
    "$route.params.id": {
      handler: function () {
        this.gS();
      },
    },
  },
  methods: {
    async gSM1() {
      await this.axios
        .get(`categories?take=100&skip=${this.currentPage - 1}`)
        .then((res) => {
          this.categories = [];
          this.categories = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM2() {
      await this.axios
        .get(`subcategory?take=100&skip=0`)
        .then((res) => {
          this.subcategories = [];
          this.subcategories = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM3() {
      await this.axios
        .get(`subcategory?take=100&skip=0`)
        .then((res) => {
          this.subcategories = [];
          this.subcategories = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async updateResearch() {
      if (isNaN(this.editform.category_id)) {
        this.editform.category_id = this.editform.category_id["id"];
      }
      await this.axios
        .post(`subcategory/${this.editform.id}`, this.editform,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
            this.notifi.route = "subcategory-notif";
            this.notifi.title = "update action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "subcategory";
            this.notifi.role_id = 1;
            this.notifi.type = "light-worning";
            this.notifi.subtitle = "updated a subcategory";
            this.notifi.bg = "bg-worning";
            this.sendNoti();
          }
          this.rEF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
          this.rEF();
        });
    },
    rF() {
      this.form.en_title = "",
      this.form.ar_title = "",
      this.form.category_id = ""
    },
    rEF() {
      this.form.en_title = "",
      this.form.ar_title = "",
      this.form.category_id = "",
      this.editform.id = ""
    },
    async aNR() {
      if (isNaN(this.form.category_id)) {
        this.form.category_id = this.form.category_id["id"];
      }
      await this.axios
        .post(`subcategory`, this.form,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
            this.notifi.route = "subcategory-notif";
            this.notifi.title = "add action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "subcategory";
            this.notifi.role_id = 1;
            this.notifi.type = "light-Success";
            this.notifi.subtitle = "added new subcategory";
            this.notifi.bg = "bg-success";
            this.sendNoti();
          }
          this.rF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    async sendNoti() {
      await this.axios.post(`notifications`, this.notifi);
    },
    async deleteResearch() {
      await this.axios
        .delete(`subcategory/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
            this.notifi.route = "subcategory-notif";
            this.notifi.title = "delete action";
            this.notifi.item_id = res.data.data.id;
            this.notifi.table = "subcategory";
            this.notifi.role_id = 1;
            this.notifi.type = "light-danger";
            this.notifi.subtitle = "deleted subcategory";
            this.notifi.bg = "bg-danger";
            this.sendNoti();
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      this.editform.en_title = item.en_title;
      this.editform.ar_title = item.ar_title;
      this.editform.category_id = item.category_id;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.ar_title = item.ar_title;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gS(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      await this.axios
        .get(
          `subcategory?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&teacher_id=${this.teacher_id}&search=${this.filter}&show=${this.$route.params.id}
          &show=${this.$route.params.id}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },

    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    onClicked(path) {
      window.open(`${path.link}`, "_blank");
      // window.open(`${filesUrl}/${path}`, "_blank");
    },
  },
};
</script>
